













import { XKonva, TOOL, ToolbarData, CosData, ElementData } from "@/xkonva/xKonva";
import { Component ,Vue } from "vue-property-decorator";
import keyboardjs from "keyboardjs";

import XCanvasToolbar from "./XCanvasToolbar.vue";
import { SelectedNodesEvent } from "@/xkonva/xTransformer";


@Component({
    components: { XCanvasToolbar }
})
export default class Canvas extends Vue {

    private xKonva!: XKonva;

    private toolbarData: ToolbarData = {
      tool: TOOL.SELECT,
      lastUpdate: Date.now()
    }

    mounted() {
      this.xKonva = new XKonva(this.$refs.stage, { width: this.$el.clientWidth, height: this.$el.clientHeight });
       this.xKonva.setTool(TOOL.SELECT);
       keyboardjs.bind('r', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.RECTANGLE);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.RECTANGLE };
        });
        keyboardjs.bind('v', (e) => {
          // e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.SELECT);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.SELECT };
        });
        keyboardjs.bind('l', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.LINE);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.LINE };
        });
        keyboardjs.bind('c', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.CIRCLE);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.CIRCLE };
        });
        keyboardjs.bind('a', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.ARROW);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.ARROW };
        });
        keyboardjs.bind('t', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.TEXT);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.TEXT };
        });
        keyboardjs.bind('h + 3', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.POLYGON);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.POLYGON };
        });
        keyboardjs.bind('p', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.setTool(TOOL.PEN);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.PEN };
        });
        keyboardjs.bind('delete', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
          this.xKonva.removeShape(true);
        });
        keyboardjs.bind('ctrl + 1', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          this.xKonva.stageZoom(100);
        });
        keyboardjs.bind('ctrl + z', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          this.xKonva.undo();
        });
        keyboardjs.bind('ctrl + r', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          this.xKonva.redo();
        });
        keyboardjs.bind('space', (e) => {
          e?.preventDefault();
          e?.preventRepeat();
          this.xKonva.setTool(TOOL.PAN);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.PAN };
          (this.$el as HTMLElement).style.cursor = "grab"; // set cursor to grab to indicate pan tool is selected
        }, () => {
          this.xKonva.setTool(TOOL.SELECT);
          this.toolbarData = { ...this.toolbarData, tool: TOOL.SELECT };
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
        });

        this.xKonva.on('selectionChange', (data: SelectedNodesEvent) => {
          this.toolbarData = { ...this.toolbarData , element: data.node, lastUpdate: Date.now() };
        })


        this.xKonva.on('zoom', (e) => {
          this.toolbarData = { ...this.toolbarData, zoom: e, lastUpdate: Date.now() };
        })
    }


    public elementDataChange(elementData: { type: string; data: CosData | ElementData }) {
      if (elementData.type === "cos") {
        const data = elementData.data as CosData;
        this.xKonva.setColor(data.solidColor);
        this.xKonva.setOpacity(data.opacity);
        this.xKonva.setStrokeWidth(Number.parseInt(data.strokeWidth));
        this.toolbarData = { ...this.toolbarData, lastUpdate: Date.now() };
      } else if (elementData.type === "element") {
        const data = elementData.data as ElementData;
        this.xKonva.shapeFill(!data.noFill);
        this.xKonva.lockShape(data.lock ?? false);
        this.xKonva.textStyle("bold", !data.textBold);
        this.xKonva.textStyle("italic", !data.textItalic);
        if (data.textJustify) {
          this.xKonva.textJustify(data.textJustify);
        }
      }
    }


    private setCanvasTool(toolbarData: ToolbarData) {
      this.xKonva.setTool(toolbarData.tool);
      this.toolbarData.tool = toolbarData.tool
      if (toolbarData.tool === TOOL.PAN) {
          (this.$el as HTMLElement).style.cursor = "grab"; // set cursor to grab
      } else {
          (this.$el as HTMLElement).style.cursor = "default"; // set cursor to default
      }
    }


    private performAction(action: string, ...args: string[]) {
      switch (action) {
        case "DUPLICATE":
          this.xKonva.duplicate();
          break;
      
        case "DELETE":
          this.xKonva.removeShape(true);
          break;

        case "ZO":
          this.xKonva.stageZoom(-0.5);
          break;
        
        case "ZI":
          this.xKonva.stageZoom(0.5);
          break;

        case "IMAGE":
          this.xKonva.insertImage(args[0][0]);
      }
    }

   
}
