import keyboardJS from "keyboardjs";
import Konva from "konva";
import { Node, NodeConfig } from "konva/types/Node";
import { Shape, ShapeConfig } from "konva/types/Shape";
import { EditText } from "../shapes/EditText";


export interface SelectedNodesEvent {
    node?: Node;
    [key: string]: any;
}


export interface XTransformerConfig extends Konva.TransformerConfig {
    /** minimum number of shapes need to be selected for bound box to appear. */
    minimumBoundBoxShapes?: number; 
    useBoundBox?: boolean;
    boundBoxLayer?: Konva.Layer;
}


export class XTransformer extends Konva.Transformer {

    private boundBoxLayer: Konva.Layer;
    private enableNudge = true;

    private handleKeyPressFunction: (e: KeyboardEvent) => void;
    private windowKeyPressHandler: (e: KeyboardEvent) => void;

    constructor (private baseLayer: Konva.Layer,private config?: XTransformerConfig) {
        super(config);
        if (config) {
            this.boundBoxLayer = config.boundBoxLayer ?? new Konva.Layer();
        } else {
            this.boundBoxLayer = new Konva.Layer();
        }
        this.keepRatio(false);
        this.boundBoxLayer.name('xBoundingBoxLayer');
        this.name('xTransformer');
        this.boundBoxLayer.listening(false); // set this layer to stop listening to events
        this.anchorCornerRadius(50);
        this.attachHandlers(); //  attach all handlers
        baseLayer.getStage().add(this.boundBoxLayer); // add layer to main stage
        this.boundBoxLayer.moveToTop(); // move this layer to top
        baseLayer.add(this);
        baseLayer.batchDraw();

        this.boundBoxLayer.setAttrs({
            fill: "blue"
        });
        this.boundBoxLayer.batchDraw();

        keyboardJS.bind('shift', () => {
            this.keepRatio(true);
        }, () => {
            this.keepRatio(false);
        })

        this.handleKeyPressFunction = (e: KeyboardEvent) => {
            if (this.nodes().length > 0) {
                if (! this.enableNudge) return;
                e.preventDefault();
                const nodesToModify = this.nodes();
                switch (e.key) {
                    case "ArrowUp":
                        if (!e.altKey) {
                            nodesToModify.forEach(n => {
                                n.position({ x: n.x(), y: n.y() - (e.shiftKey?10:1) });
                                n.fire('dragend');
                            });
                        } else {
                            if (! nodesToModify.find((n) => n instanceof EditText)) {
                                nodesToModify.forEach(n => {
                                    n.size({ height: n.height() - (e.shiftKey?10:1), width: n.width()})
                                    n.fire('transformend');
                                });
                            }
                        }
                        break;

                    case "ArrowDown":
                        if (!e.altKey) {
                            nodesToModify.forEach(n => {
                                n.position({ x: n.x(), y: n.y() + (e.shiftKey?10:1) })
                                n.fire('dragend');
                            });
                        } else {
                            if (! nodesToModify.find((n) => n instanceof EditText)) {
                                nodesToModify.forEach(n => {
                                    n.size({ height: n.height() + (e.shiftKey?10:1), width: n.width()})
                                    n.fire('transformend');
                                });
                            }
                        }

                        break;

                    case "ArrowRight":
                        if (!e.altKey) {
                            nodesToModify.forEach(n => {
                                n.position({ x: n.x() + (e.shiftKey?10:1), y: n.y()  })
                                n.fire('dragend');
                            });
                        } else {
                            if (! nodesToModify.find((n) => n instanceof EditText)) {
                                nodesToModify.forEach(n => {
                                    n.size({ height: n.height() , width: n.width() + (e.shiftKey?10:1)})
                                    n.fire('transformend');
                                });
                            }
                        }

                        break;

                    case "ArrowLeft":
                        if (!e.altKey) {
                            nodesToModify.forEach(n => {
                                n.position({ x: n.x() - (e.shiftKey?10:1), y: n.y()  })
                                n.fire('dragend');
                            });
                        } else {
                            if (! nodesToModify.find((n) => n instanceof EditText)) {
                                nodesToModify.forEach(n => {
                                    n.size({ height: n.height(), width: n.width() - (e.shiftKey?10:1)})
                                    n.fire('transformend');
                                });
                            }
                        }

                        break;
                }
                this.fireSelectedNodeEvent();
                this.baseLayer.draw();
            }
        };
        this.windowKeyPressHandler = this.handleKeyPressFunction.bind(this);
        window.addEventListener('keydown', this.windowKeyPressHandler);

    }

    /**
     * Attach all related handler to this transformer
     */
    private attachHandlers() {
        this.on('dragmove transform', () => {
            this.fireSelectedNodeEvent();
            this.updateBoundBox();
        });
    }


    private fireSelectedNodeEvent() {
        let target: Node;
        if (this.nodes().length === 1) {
            target = this.nodes()[0];
        } else {
            target = this;
        }
        this.fire('selectedNodes', { node: target } as SelectedNodesEvent);
    }

    /**
     * Update bounding box layer
     */
    private updateBoundBox() {

        this.fireSelectedNodeEvent();

        if (! (this.config && this.config.useBoundBox)) {
            return;
        }
        this.boundBoxLayer.removeChildren();
        if (this.nodes().length >= Math.max((this.config?.minimumBoundBoxShapes ?? 2), 1)) {
            this.nodes().forEach((s: Node<NodeConfig> | Shape<ShapeConfig>) => {
                const r = s.getClientRect();
                this.boundBoxLayer.add(new Konva.Rect({ ...r, name: "xBoundingBox" ,stroke: '#1F57F2', strokeWidth: 1 , fillEnabled: false }));
                if (!this.boundBoxLayer.isVisible()) {
                    this.boundBoxLayer.visible(true);
                    if (this.boundBoxLayer.isListening()) {
                        this.boundBoxLayer.listening(false);
                    }
                }
            })
        }
        this.boundBoxLayer.batchDraw();
    }

    /**
     * deselect all node or select inverse
     * @param inverse deselect current nodes and select inverse
     */
    public deselectNode(inverse = false) {
        if (this.nodes().length > 0) {
            if (inverse) {
                const shapeIds = this.nodes().map((node) => node.id());
                const nodesToSelect = this.baseLayer.children.toArray().filter((shape) => !shapeIds.includes(shape.id()));
                this.selectNode(...nodesToSelect); // select inverse
            } else {
                this.selectNode(); // clear all selectedNodes
            }
        }
    }

    /**
     * Node to select
     * @param target Node to select
     */
    public selectNode(...target: Node[]) {
        this.enableNudge = true;

        // here we need to manually attach or detach Transformer node
        if (target && (target.length > 0)) {
            this.enabledAnchors(['middle-left', 'middle-right','top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center']);
            if (target.length == 1) {
                switch (target[0].className) {
                    case "Line":
                    case "Arrow":
                        this.enabledAnchors(['middle-left', 'middle-right']);
                        break;
                        
                    case "Text":
                        this.enabledAnchors(['middle-left', 'middle-right']);
                        break;
                        
                    case "Image":
                        this.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right']);
                        break;
                }

                if (! target[0].draggable()) {
                    this.enableNudge = false;
                    this.enabledAnchors([]);
                    this.rotateEnabled(false);
                } else {
                    this.rotateEnabled(true);
                }
            } else {
                target = target.filter((t) => t.draggable());
                if (target.length === 0) {
                    this.deselectNode();
                    return;
                }
                if (target.find((n) => n instanceof EditText)) {
                    this.enabledAnchors([]);
                }
                this.rotateEnabled(true);
            }
            this.nodes(target);
            this.updateBoundBox();
        } else {
            this.nodes([]);
            this.boundBoxLayer.removeChildren();
            if (this.boundBoxLayer.isVisible()) {
                this.boundBoxLayer.visible(false);
            }
            this.fire('selectedNodes', {})
        }
        this.moveToTop();
        this.baseLayer.batchDraw();
    }
}