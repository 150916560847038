








import { Component, Vue } from 'vue-property-decorator';
import HeaderBar from '@/components/HeaderBar.vue';
import Canvas from '@/components/Canvas.vue';

@Component({
  components: {
    Canvas,
    HeaderBar
  },
})
export default class App extends Vue {}
