












































































































































































import { ElementData, TEXT_JUSTIFY } from "@/xkonva/xKonva";
import { XTransformer } from "@/xkonva/xTransformer";
import Konva from "konva";
import { Node } from "konva/types/Node";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class XToolbarElement extends Vue {

    private _menuType = "d-none";

    @Prop({ default: 0 }) updatePosition!: number;
    @Watch('updatePosition', { immediate: true})
    watchUpdatePosition(updateTimestamp: number) {
      if (updateTimestamp) {
        if (this.elementNode === null) {
          this._menuType = "d-none";
        } else {
          this.elementData.lock = !this.elementNode.draggable();
          if(this.elementNode.className === "Text") {
          this._menuType = "cet-t";
          this.elementData.textBold = (this.elementNode as Konva.Text).fontStyle().includes('bold');
          this.elementData.textItalic = (this.elementNode as Konva.Text).fontStyle().includes('italic')
          this.elementData.textJustify = (this.elementNode as Konva.Text).align().toUpperCase() as TEXT_JUSTIFY;
        } else if (this.elementNode.className === "Transformer"){
          this._menuType = "cet-c";
          this.elementData.noFill = false;
          const tr = this.elementNode as XTransformer;
          tr.nodes().filter((n) => n.className !== "Image").forEach((c) => {
            const child = c as Konva.Shape;
            if (child.fill().slice(7,9) === "00") {
              this.elementData.noFill = true;
            }
          });
          this.elementData.lock = false;
        } else if (this.elementNode.className === "Image") {
          this._menuType = "cet-i";
        } else {
          this._menuType = "cet-c";
          this.elementData.noFill = (this.elementNode as Konva.Shape).fill().slice(7,9) === "00";
        }

        const element = this.$el as HTMLElement;

        const transformer = this.elementNode.getLayer()?.findOne('.xTransformer');
        if (! transformer) return; // return if tranformer is not there;

        const stage = transformer.getStage();
        if (! stage) return; // return if stage is not set for transformer

        const shapeRect = transformer.getClientRect();
        const left = shapeRect.x + shapeRect.width + 10;
        const top = shapeRect.y + 25;
        
        
        if (left < 20) {
            element.style.left = 20 + "px";
        } else if((stage.width() - left) > 60) {
            element.style.left = left + "px";
        } else {
            const pseudoLeft = left - shapeRect.width ;
            if (pseudoLeft > stage.width()) {
                element.style.left = stage.width() - element.clientWidth - 20 + "px";
            } else {
                element.style.left = pseudoLeft - element.clientWidth - 20 + "px";
            }
        }

        if ((stage.height() - (top + element.clientHeight)) < 20) {
            element.style.top = stage.height() - element.clientHeight - 20 + "px";
        } else if (top < 20){
            element.style.top = 20 + "px";
        } else {
            element.style.top = top + "px";
        }
        }
      }
      this.$forceUpdate();
    }
    
    @Prop({ default: 'd-none' }) menuType!: string; 
    @Watch('menuType', { immediate: true })
    watchMenuType(menuType: string) {
      this._menuType = menuType;
      this.$forceUpdate();
    }
    @Prop() elementNode!: Node | null; // Element data from parent
    
    /**
     * Internal state for element
     */
    public elementData: ElementData = {
        textBold: false,
        textItalic: false,
        textJustify: TEXT_JUSTIFY.LEFT,
        noFill: true
    }

    /**
     * Emit `action` event to perform action by parent
     */
    private callAction(action: string) {
        this.$emit('action', action);
    }


    private changeEvent() {
        this.$emit('elementDataChange', this.elementData);
    }
}
