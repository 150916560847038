






















































































































































































import { CosData } from "@/xkonva/xKonva";
import Konva from "konva";
import { Node } from "konva/types/Node";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

/**
 * Class XToolbarColorOpacityStroke
 * @emits CosData cosDataChange
 */
@Component
export default class XToolbarColorOpacityStroke extends Vue {
  private _menuType = "d-none";

  @Prop() menuType!: string;
  @Watch("menuType", {
    immediate: true
  })
  watchMenuType(menuType: string) {
    this._menuType = menuType;
    this.$forceUpdate(); // NOTE this is required as updating menuType isn't updating component if element is empty|null
  }

  @Prop() elementNode!: Node | null;

  @Watch("elementNode", {
    immediate: true
  })
  watchElementNode(element: Node | null) {
    if (element) {
      const shape = element as Konva.Shape;
      // Check if shape is transformer
      if (shape.className === "Transformer") {
        this._menuType = "color-pane-1";
      } else if (shape.className === "Image") {
        this._menuType = "d-none"
      } else {
        const rawColor = shape.fill() ?? shape.stroke();
        this.cosData.solidColor = rawColor.slice(0, 7).toUpperCase();
        this.cosData.opacity = rawColor.slice(7).toUpperCase() ?? "FF";
        this.cosData.strokeWidth = shape.strokeWidth().toString() ?? "2";
        if (element instanceof Konva.Text) {
          this._menuType = "color-pane-1";
        } else {
          this._menuType = "color-pane-3";
        }
      }
    } else {
      this._menuType = "d-none";
    }
    this.$forceUpdate(); // NOTE this is required as updating menuType isn't updating component if element is empty|null
  }

  /**
   * Internal cos data
   */
  public cosData: CosData = {
    solidColor: "#000000",
    opacity: "FF",
    strokeWidth: "2",
  };

  /**
   * On change function to relay/emit data any data change from component
   */
  public changeCosDataParameter() {
    this.$emit("cosDataChange", this.cosData); // emit cosDataChange event to parnet
  }
}
