
















































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import XToolbarElement from "./XToolbarElement.vue";
import XToolbarColorOpacityStroke from "./XToolbarColorOpacityStroke.vue";
import { CosData, ElementData, TOOL, ToolbarData } from '@/xkonva/xKonva';
import { Node } from "konva/types/Node";
import keyboardjs from "keyboardjs";


@Component({
  components: {
    XToolbarColorOpacityStroke,
    XToolbarElement
  },
  data: function () {
    return {
      zoom: 1
    }
  }
})
export default class XCanvasToolbar extends Vue {

    private lastTool = TOOL.SELECT; // last tool selected 
    public elementNode: Node | null = null;
    public zoom = 100;

    private updateElementPosition = 0;

    public cosMenuType = "d-none";

    @Prop() toolbarData!: ToolbarData; // toolbar data from parent

    /**
     * Watcher for toolbar data to observe change and relay to and fro from components
     */
    @Watch('toolbarData', { immediate: true })
    private watchToolbarData(toolbarData: ToolbarData) {
      this.elementNode = toolbarData.element ?? null;
      this.lastTool = toolbarData.tool;
      this.updateElementPosition = toolbarData.lastUpdate ?? 0;
      this.zoom = toolbarData.zoom?Math.round(toolbarData.zoom*100):100;
    }

    /**
     * Emit event to parent regarding data change in cos toolbar
     */
    private cosDataChange(cosData: CosData) {
      this.$emit('dataChange', { type: "cos", data: cosData });
    }

    /**
     * Emit event to parent regarding data change in cos toolbar
     */
    private elementDataChange(elementData: ElementData) {
      this.$emit('dataChange', { type: "element", data: elementData });
    }

    private actionElement(action: string, ...args: string[]) {
      this.$emit('action', action, args);
    }

    imageModal = false;
    imageSrc = "";
    imageLink = "";

    loadImage(ev: Event) {
      const inputTarget = ev.target as HTMLInputElement;
      if (!inputTarget.files) {
        return;
      }
      if (inputTarget.files.length < 1) {
        return;
      }
      const fileToLoad = inputTarget.files[0];
      this.imageSrc = URL.createObjectURL(fileToLoad);
    }

    showImageModal() {
        this.imageModal = !this.imageModal;
        if (this.imageModal) {
          keyboardjs.pause();
        } else {
          keyboardjs.resume();
        }
    }

    insertImage() {
      this.actionElement('IMAGE', this.imageLink || this.imageSrc)
      this.imageSrc = "";
      this.imageLink = "";
      this.showImageModal();
    }

    changeTool(e: Event) {
      if (! e.target) return;
      const radioButton = e.target as HTMLInputElement;
      if (radioButton.value === TOOL.TEXT.toString()) {
        this.cosMenuType = "color-pane-1";
      } else if (radioButton.value === TOOL.SELECT.toString()) {
        this.cosMenuType = "d-none";
      } else {
        this.cosMenuType = "color-pane-3"
      }
      this.$emit('toolbarDataChange', {...this.toolbarData, tool: Number.parseInt(radioButton.value)});
    }

}
