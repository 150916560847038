import Vue from 'vue'
import App from './App.vue'

import vueKonva from 'vue-konva';

Vue.config.productionTip = false

Vue.use(vueKonva);

new Vue({
  render: h => h(App),
}).$mount('#app')
