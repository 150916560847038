import Konva from "konva";
import { NodeConfig } from "konva/types/Node";
import { XKonva } from "../xKonva";


export enum OPERATION {
    ADDED,
    /**
     * @deprecated Will be removed in subsequent version
     */
    MODIFIED,
    SCALED,
    ROATATED,
    MOVED,
    DELETED
}


interface XState {
    id: number; // Not unique if it was group/multiselect move
    operation: OPERATION;
    shapeId: number;
    timestamp: number;
}

interface ShapeState {
    _id: number;
    current: number;
    states: {
        modId: number;
        data: NodeConfig;
    }[];
}

/**
 * State manager for xkonva library
 */
export class XStateManager {
    private loadedStates: XState[] = [];
    private shapeStates: ShapeState[] = [];
    public getStates(): XState[] {
        return this.loadedStates;
    }

    private _stateIndex = 0; // current state index
    public get stateIndex() {
        return this._stateIndex;
    }

    /**
     * Add state to the state manager
     * @param operation type of state change
     * @param data data that has been changed
     * @returns {boolean} if state save was success it returns true. otherwise false.
     */
    public addState(operation: OPERATION, ...data: Konva.Node[]): boolean {
        if (data.length > 0) {
            /**
             * FIXME delete all exisiting state if current stateIndex is not last state
             */

            if (operation !== OPERATION.MODIFIED) {
                this.loadedStates = this.loadedStates.filter(ls => ls.id <= this.stateIndex);
                this.shapeStates = this.shapeStates.filter(ss => ss.current > -1)
                                        .map<ShapeState>(ss => {
                                            ss.states.splice(ss.current + 1);
                                            return ss;
                                        });
            }
            const currentStateId = ++this._stateIndex;
            const stateLastIndex = this.loadedStates.push(...data.map<XState>((d) => {
                const xstate: XState = {
                    id: currentStateId,
                    shapeId: d._id,
                    operation,
                    timestamp: Date.now()
                }
                const foundOn: number = this.shapeStates.findIndex((s) => s._id === d._id);
                const shapeState: ShapeState = this.shapeStates[foundOn] ?? {
                    _id: d._id,
                    current: 0,
                    states: []
                };
                // Check if there are shape states
                // That means its not newly created state
                if (shapeState.states.length !== 0) {
                    shapeState.current = shapeState.current + 1; // increment the counter by one
                }
                shapeState.states.push({ data: {...d.attrs} , modId: currentStateId});
                if (foundOn < 0) {
                    this.shapeStates.push(shapeState);
                }
                return xstate;
            })) - 1;
            this._stateIndex = (stateLastIndex === 0)?stateLastIndex + 1:this._stateIndex;

            return true;
        }
        return false;
    }


    public undo(applyTo: Konva.Layer) {
        // Get current state and shape state
        const currentState = this.loadedStates.find(ls => ls.id === this.stateIndex); if (!currentState) return;
        const currentStateShape = this.shapeStates.find(ss => ss._id === currentState.shapeId); if (!currentStateShape) return;

        // find current shape in layer
        const shapeInLayer = applyTo.getChildren().toArray().find(as => as._id === currentStateShape._id);
        if (!shapeInLayer) return;

        // Check for opreation type
        if (currentState.operation === OPERATION.ADDED) {
            shapeInLayer.hide(); // hide the shape
        } else if (currentState.operation === OPERATION.DELETED) {
            shapeInLayer.show(); // show the shape
        } else {
            // get states of current shape
            const shapeStates = currentStateShape.states;
            // Check if its in intital state
            if (currentStateShape.current <= 0) return;
            // Get previous state of the current shape
            const prevShapeState = shapeStates[--currentStateShape.current];
            shapeInLayer.setAttrs(prevShapeState.data);
        }
        applyTo.draw();
        this._stateIndex--;
    }


    public redo(applyTo: Konva.Layer) {
        // Get next state and shape state
        const nextState = this.loadedStates.find(ls => ls.id === this.stateIndex + 1); if (!nextState) return;
        const nextStateShape = this.shapeStates.find(ss => ss._id === nextState.shapeId); if (!nextStateShape) return;

        // find current shape in layer
        const shapeInLayer = applyTo.getChildren().toArray().find(as => as._id === nextStateShape._id);
        if (!shapeInLayer) return;

        // Check for opreation type
        if (nextState.operation === OPERATION.ADDED) {
            shapeInLayer.show(); // hide the shape
        } else if (nextState.operation === OPERATION.DELETED) {
            shapeInLayer.hide(); // show the shape
        } else {
            // get states of current shape
            const shapeStates = nextStateShape.states;
            // Check if its in intital state
            if (nextStateShape.current >= shapeStates.length - 1) return;
            // Get previous state of the current shape
            const prevShapeState = shapeStates[++nextStateShape.current];
            shapeInLayer.setAttrs(prevShapeState.data);
        }
        applyTo.draw();
        this._stateIndex++;
    }

     /**
     * Move state to pointed state  
     * set `pointer` to `-Infinity` for `undo operation`  
     * set `pointer` to `Infinity` for `redo operation`  
     * @param {number} pointer index of state to go to
     * @param {Konva.Layer} applyTo layer to apply the changes
     * 
     * @deprecated
     */
    public moveState(operate: "UNDO" | "REDO", applyTo: Konva.Layer) {
        if (this.stateIndex > this.loadedStates.length) {
            this._stateIndex = this.loadedStates.length;
        } else if (this.stateIndex < 1) {
            this._stateIndex = 0;
        }
        let statesToApply: XState[];
        if (operate === "UNDO") {
            // Get current states from xStates
            statesToApply = this.loadedStates.filter((s) => s.id === this.stateIndex);
            statesToApply.forEach(s => {
                const shapeState = this.shapeStates.find(ss => ss._id === s.shapeId);
                if (!shapeState) return;
                let stateIndex;
                if (shapeState.current < 0) {
                    stateIndex = 0
                } else if (shapeState.current > shapeState.states.length - 1) {
                    stateIndex = shapeState.states.length -1;
                    shapeState.current--;
                } else if (shapeState.current === 0) {
                    stateIndex = 0;
                    shapeState.current--;
                } else {
                    stateIndex = shapeState.current - 1;
                    shapeState.current--;
                }
                const shapeStateToApply = shapeState.states[stateIndex];
                if (!shapeStateToApply) return;
                if (applyTo) {
                    // find the shape in layer
                    const shapeLayer = applyTo.getChildren().toArray().find(sl => sl._id === shapeState._id);
                    if (!shapeLayer) return;
                    // apply state to the layer shape
                    if (s.operation === OPERATION.ADDED) {
                        shapeLayer.hide();
                    } else if (s.operation === OPERATION.DELETED) {
                        shapeLayer.show();
                    } else {
                        shapeLayer.setAttrs(shapeStateToApply.data);
                    }
                    applyTo.batchDraw(); 
                }
            });
            this._stateIndex--;
            
        } else if(operate === "REDO") {
            statesToApply = this.loadedStates.filter((s) => s.id === this.stateIndex + 1);
            statesToApply.forEach(s => {
                const shapeState = this.shapeStates.find(ss => ss._id === s.shapeId);
                if (!shapeState) return;
                let stateIndex;
                if (shapeState.current < 0) {
                    stateIndex = 0
                    shapeState.current++;
                } else if (shapeState.current >= shapeState.states.length - 1) {
                    stateIndex = shapeState.states.length - 1;
                } else {
                    stateIndex = shapeState.current + 1;
                    shapeState.current++;
                }
                const shapeStateToApply = shapeState.states[stateIndex];
                if (!shapeStateToApply) return;
                if (applyTo) {
                    // find the shape in layer
                    const shapeLayer = applyTo.getChildren().toArray().find(sl => sl._id === shapeState._id);
                    if (!shapeLayer) return;
                    // apply state to the layer shape
                    if (s.operation === OPERATION.ADDED) {
                        shapeLayer.show();
                    } else if (s.operation === OPERATION.DELETED) {
                        shapeLayer.hide();
                    } else {
                        shapeLayer.setAttrs(shapeStateToApply.data);
                    }
                    applyTo.batchDraw(); 
                }
            });
            this._stateIndex++;
        }
    }


    /**
     * Move state to pointed state  
     * set `pointer` to `-Infinity` for `undo operation`  
     * set `pointer` to `Infinity` for `redo operation`  
     * @param {number} pointer index of state to go to
     * @param {Konva.Layer} applyTo layer to apply the changes
     * 
     * @deprecated
     */
    public moveStateOld(operate: "UNDO" | "REDO", applyTo?: Konva.Layer): ShapeState[] | undefined {
        let statesToApply: XState[];
        switch (operate) {
            case "UNDO":
                // Check if current state is the first state
                if (this.stateIndex <= 1) {   
                    this._stateIndex = 1; // force it to initial state and do nothing
                    // return;
                }
                statesToApply = this.loadedStates.filter((s) => s.id === this.stateIndex);
                --this._stateIndex; // NOTE Do not decrement stateIndex below
                break;
            case "REDO":
                // Check if current state is the last state
                if (this.stateIndex > this.loadedStates.length) {
                    this._stateIndex = this.loadedStates.length; //  force it to the last state and do nothing
                    return;
                }
                ++this._stateIndex; // NOTE Do not increment stateIndex below
                statesToApply = this.loadedStates.filter((s) => s.id === this.stateIndex);
                break;
        }
        if (applyTo && statesToApply.length > 0) {
            statesToApply.forEach(s => {
                const currentShapeState = this.shapeStates.find((ss) => ss._id === s.shapeId);
                if (!currentShapeState) return; // if shape is undefined; do nothing.
                let currentState = currentShapeState.current;
                let stateToApply: { modId: number;data: NodeConfig };
                let layerShape: Konva.Node | undefined;
                let modReleatedToShape: XState | undefined;
                switch (operate) {
                    case "UNDO":
                        currentState--;
                        // Check if its the first state
                        if (currentState <= 0) {
                            currentShapeState.current = 0; // force it to initial state
                        }
                        stateToApply = currentShapeState.states[currentShapeState.current];
                        layerShape = applyTo.getChildren()
                        .toArray()
                        .find((layerShape) => layerShape._id === s.shapeId);
                        modReleatedToShape = this.loadedStates.find((ss) => ss.id === stateToApply.modId);
                        if (!modReleatedToShape) return;
                        if (modReleatedToShape.operation === OPERATION.ADDED && currentState < 0) {
                            layerShape?.hide();
                        } else if (modReleatedToShape.operation === OPERATION.DELETED) {
                            layerShape?.show();
                        } else {
                            layerShape?.setAttrs(stateToApply.data);
                        }
                        
                        break;
                        
                    case "REDO":
                        currentState++
                        // Check if its the last state
                        if (currentState >= currentShapeState.states.length - 1) {
                            currentShapeState.current = currentShapeState.states.length - 1; // force it to last state
                        }
                        stateToApply = currentShapeState.states[currentShapeState.current];


                        layerShape = applyTo.getChildren()
                        .toArray()
                        .find((layerShape) => layerShape._id === s.shapeId);
                        modReleatedToShape = this.loadedStates.find((ss) => ss.id === stateToApply.modId);
                        if (!modReleatedToShape) return;
                        if (modReleatedToShape.operation === OPERATION.ADDED) {
                            layerShape?.show();
                        } else if (modReleatedToShape.operation === OPERATION.DELETED) {
                            layerShape?.hide();
                        } else {
                            layerShape?.setAttrs(stateToApply.data);
                        }

                        break;
                }
                currentShapeState.current = (currentState < 0)?0:currentState;
                currentShapeState.current = (currentShapeState.current >= currentShapeState.states.length - 1)?currentShapeState.states.length -1:currentShapeState.current;
            });
            applyTo.draw();
        }
        return statesToApply.map((x) => this.shapeStates.filter((s) => s._id === x.id)).flat();
    }

    /**
     * Pass xKonva class to save the state
     * @param {XKonva} xKonva object to save state to local storage
     * @param onSuccess
     * @deprecated Save to local storage will be removed in subsequent version
     */
    public saveStage(xKonva: XKonva, onSuccess?: Function): boolean {
        const layerToSave = xKonva.baseLayer;
        let valueToSave = layerToSave.toJSON();
        if (valueToSave) {
            valueToSave = btoa(valueToSave); // convert to base 64 string
            localStorage.setItem('xubikle_canvas', valueToSave);
            if (onSuccess) {
                onSuccess(); // if callback is provided, execute it
            }
        }
        return true;
    }
    
    /**
     * Load base layer data from given bas64 string  
     *   
     * if there is no stage to load an empty/blank base layer is returned
     * @returns {Konva.Layer} base layer
     * @deprecated Loading stage from local storage will be removed
     */
    public loadStage(): Konva.Layer {
        const localSaveStorage = localStorage.getItem('xubikle_canvas'); // data is in base64
        const baseLayer = new Konva.Layer({
            name: "xBaseLayer" // NOTE this name should not be repeated
        });
        if (localSaveStorage) {
            const layer: { children: {className: string; attrs: NodeConfig}[]; [key: string]: any} = JSON.parse(atob(localSaveStorage));
            layer.children.forEach((shape) => {
                
                if (shape.className === "Group") {
                    /**
                     * TODO add support for group object loading
                     */
                } else if (shape.className === "Transformer" || shape.attrs.name === "xSelect") {
                    /**
                     * NOTE some feature need to be added
                     */
                } else {
                    // Add any other shape to base layer
                    // TODO need to handle image loading. images are not saved in the model need to load from localstorage/chache
                    baseLayer.add(Konva.Shape.create(shape));
                }
            });
        }
        return baseLayer;
    }
}
