import Konva from "konva";

export class EditText extends Konva.Text {

    private textarea: HTMLTextAreaElement;
    private windowClickFunction: (e: MouseEvent) => void;
    private windowClickHandler: (e: MouseEvent) => void;
    private prevText = "";

    constructor (config?: any) {
        super(config);

        this.textarea = document.createElement('textarea');

        this.prevText = this.text();

        /**
         * NOTE This window function are the references to remove and add eventlistener to window object
         */
        this.windowClickFunction = (e: MouseEvent) => {
            if (e.target !== this.textarea) {
              if (this.text() !== this.textarea.value) {
                this.show();
              this.fire('transformend');
              this.hide();
              }
                this.text(this.textarea.value);
                this.removeTextarea();
                this.getStage()?.fire('click');
            }
        };
        this.windowClickHandler = this.windowClickFunction.bind(this);

        this.on('dblclick dbltap', () => this.handleDoubleClick());
        this.on('transform', () => {
            this.setAttrs({
                width: Math.max(this.width() * this.scaleX(), 50),
                scaleX: 1
            })
        })
    }


    private handleDoubleClick() {

        this.hide();

        const textPosition = this.absolutePosition();
        
        // then lets find position of stage container on the page:
        const stageBox = this.getStage()?.container().getBoundingClientRect();
        
        if (!stageBox) return;
        
        const pasteHandler = this.getStage()?.container().ownerDocument.defaultView as unknown as any;
        pasteHandler.listenPaste = false;
        // so position of textarea will be the sum of positions above:
        const areaPosition = {
          x: textPosition.x,
          y: textPosition.y,
        };

        // create textarea and style it
        document.body.querySelector("div.canvas")?.appendChild(this.textarea);

        this.textarea.value = this.text();
        this.textarea.style.position = 'absolute';
        this.textarea.style.top = areaPosition.y + 'px';
        this.textarea.style.left = areaPosition.x + 'px';
        this.textarea.style.width = this.width() - this.padding() * 2 + 'px';
        this.textarea.style.height = this.height() - this.padding() * 2 + 5 + 'px';
        this.textarea.style.fontSize = this.fontSize() + 'px';
        this.textarea.style.border = 'none';
        this.textarea.style.padding = '0px';
        this.textarea.style.margin = '0px';
        this.textarea.style.overflow = 'hidden';
        this.textarea.style.background = 'none';
        this.textarea.style.outline = 'none';
        this.textarea.style.resize = 'none';
        this.textarea.style.lineHeight = this.lineHeight().toString();
        this.textarea.style.fontFamily = this.fontFamily();
        this.textarea.style.transformOrigin = 'left top';
        this.textarea.style.textAlign = this.align();
        this.textarea.style.color = this.fill();
        const rotation = this.rotation();
        let transform = '';
        transform = "scale(" + this.getStage()?.scaleX().toString() + ", "+ this.getStage()?.scaleY() +")";
        if (rotation) {
          transform += 'rotateZ(' + rotation + 'deg)';
        }

        let px = 0;
        // also we need to slightly move this.textarea on firefox
        // because it jumps a bit
        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
          px += 2 + Math.round(this.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';

        this.textarea.style.transform = transform;

        // reset height
        this.textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        this.textarea.style.height = this.textarea.scrollHeight + 3 + 'px';

        this.textarea.focus();

        
        this.textarea.addEventListener('keydown', (e) => {

          // hide on enter
          // but don't hide on shift + enter
          if (e.key === "Enter" && !e.shiftKey) {
            this.prevText = this.text();
            this.text(this.textarea.value);
            this.removeTextarea();
          }
          // on esc do not set value back to node
          if (e.key === "Escape") {
            this.prevText = this.text();
            this.removeTextarea();
          }
        });

        this.textarea.addEventListener('keydown', (e) => {
          const scale = this.getAbsoluteScale().x;
        //   this.setTextareaWidth(this.width() * scale);
          this.textarea.style.height = 'auto';
          this.textarea.style.height =
            this.textarea.scrollHeight + this.fontSize() + 'px';
        });

        
        setTimeout(() => {
          window.addEventListener('click', this.windowClickHandler);
        });
    }


    private removeTextarea() {
        this.show();
        const parent = this.textarea.parentNode;
        if (this.prevText !== this.textarea.value) {
          this.fire('transformend');
        }
        if (parent) {
            parent.removeChild(this.textarea);
        }
        window.removeEventListener('click', this.windowClickHandler);
        const pasteHandler = this.getStage()?.container().ownerDocument.defaultView as unknown as any;
        pasteHandler.listenPaste = true;
        this.getStage()?.draw();
      }

    private setTextareaWidth(newWidth: number) {
        if (!newWidth) {
          // set width for placeholder
          newWidth = this.text().length * this.fontSize();
        }
        // some extra fixes on different browsers
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isSafari || isFirefox) {
          newWidth = Math.ceil(newWidth);
        }

        const isEdge =
          document.DOCUMENT_NODE || /Edge/.test(navigator.userAgent);
        if (isEdge) {
          newWidth += 1;
        }
        this.textarea.style.width = newWidth + 'px';
      }
}