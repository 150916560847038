import { EventEmitter } from "events";
import keyboardjs from "keyboardjs";
import Konva from "konva";
import { KonvaEventObject, Node, NodeConfig } from "konva/types/Node";
import { IRect, Vector2d } from "konva/types/types";
import { OPERATION, XStateManager } from "./states";
import { XTransformerConfig, XTransformer } from "./xTransformer";
import { EditText } from './shapes/EditText';
import {Vue} from "vue-property-decorator";


export interface ToolbarData {
    cosData?: CosData;
    elementData?: ElementData;
    element?: Node;
    tool: TOOL;
    lastUpdate?: number;
    zoom?: number;
}

export interface ElementData {
    element?: Node;
    textBold?: boolean;
    textItalic?: boolean;
    textJustify?: TEXT_JUSTIFY;
    lock?: boolean;
    noFill?: boolean;
}

export interface CosData {
    solidColor: string;
    opacity: string;
    strokeWidth: string;
}



export enum TEXT_JUSTIFY {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    CENTER = "CENTER"
}


export enum TOOL {
    SELECT,
    PEN,
    LINE,
    ARROW,
    CIRCLE,
    RECTANGLE,
    POLYGON,
    TEXT,
    PAN
}

export class XKonva extends EventEmitter {
    private mainStage!: Konva.Stage; // Main stage where every layer will reside

    private _version = "1.0.3"; //  version of this library
    public get version(): string {
        return this._version;
    }

    private _baseLayer: Konva.Layer;
    public get baseLayer(): Konva.Layer {
        return this._baseLayer;
    }
    public set baseLayer(layer: Konva.Layer) {
        this._baseLayer = layer;
    }
    

    private readonly drawingLayer: Konva.Layer; // drawing layer active when in drawing mode
    private color = "#000000";
    private opacity = "FF";
    private strokeWidth = 2;

    private _drawingMode = false;
    public get drawingMode(): boolean {
        return this._drawingMode;
    }
    public set drawingMode(mode: boolean) {
        this._drawingMode = mode;

        
        this.drawingLayer.visible(mode); // set drawing layer visiblity to true
        this.drawingLayer.listening(mode); // set drawing layer listening mode
        if (this.currentTool !== TOOL.SELECT) {
            this.baseLayer.listening(false); // base layer to stop listening to stage/mouse events
        } else {
            this.baseLayer.listening(true);
        }
        this.transferDrawingToBaseLayer();
    }

    /**
     * set to true if selection has started.  
     * NOTE its to check for select/point tool
     */
    private selectStarted = false; 

    private polygonSides = 3; // its for polygon


    private stateManager = new XStateManager(); // State manager library
    public getStateManager() {
        return this.stateManager; // getter for state manager
    }
    /**
     * XTransformer reladted config and property
     */
    private xTransformerConfig: XTransformerConfig = {
        anchorFill: 'white',
        anchorStroke: '#1F57F2',
        borderDash: [5, 3],
        anchorStrokeWidth: 1,
        anchorSize: 8,
        rotateAnchorOffset: 26,
        borderStroke: '#1F57F2',
        useBoundBox: false,
        minimumBoundBoxShapes: 2,
        name: 'xTransformer'
    };
    private xTransformer: XTransformer;

    private _currentTool = TOOL.SELECT;
    public get currentTool(): TOOL {
        return this._currentTool;
    }
    public set currentTool(v: TOOL) {
        this._currentTool = v;
    }

    // Pointer position used when in drawing mode
    private lastPointerPosition: Vector2d = { x: 0, y: 0 };

    // Symmetry key press
    private symmetryKeyPressed = false;
    
    


    constructor (mainStage?: Vue | Vue[] | Element | Element[] | string, config?: NodeConfig) {
        super();

        this.setStage(mainStage, config); // setting mainstage if its provided while initializing

        this._baseLayer = this.stateManager.loadStage();

        this.drawingLayer = new Konva.Layer({
            name: 'xDrawingLayer' // NOTE unique name, should not be repeated
        })
        this.drawingLayer.visible(false);

        this.mainStage.add(this._baseLayer); // adding base layer to 
        this.mainStage.add(this.drawingLayer); // adding drawing layer to 
        this.xTransformer = new XTransformer(this._baseLayer, this.xTransformerConfig);

        this.attachStageEvents();

        /**
         * NOTE Below is just for testing purpose
         */
        // this._baseLayer.add(new Konva.Rect({
        //     fill: '#1F57F100',
        //     name: 'xShape',
        //     x: 500,
        //     y: 250,
        //     width: 100,
        //     height: 100,
        //     rotation: 45,
        //     strokeWidth: 2,
        //     stroke: '#1F57F1',
        //     draggable: true,
        // }));
        
        // this._baseLayer.batchDraw();
    
        window.addEventListener('keydown', (e) => {
            this.symmetryKeyPressed = e.shiftKey;
        })
        window.addEventListener('keyup', (e) => {
            this.symmetryKeyPressed = e.shiftKey;
        });

        this.xTransformer.on('selectedNodes', (e: any) => {
            this.emit('selectionChange', e);
        })
        
        // @ts-expect-error
        window.listenPaste = true; // NOTE Important link as it allows paste functionality

        // this.insertImage("https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1402&q=80")
    }

    /**
     * Attach stage events to canavs's main stage
     */
    private attachStageEvents() {
        this.mainStage.on('click tap', (e) => this.handleMouseClick(e));
        this.mainStage.on('mousemove touchmove', (e) => this.handleMouseMove(e));
        this.mainStage.on('mousedown touchstart', (e) => this.handleMouseDown(e));
        this.mainStage.on('mouseup touchend', (e) => this.handleMouseUp(e));
        this.mainStage.on('dblclick dbltap', (e) => this.handleMouseDoubleClick(e));
        this.mainStage.on('wheel', (e) => this.handleMouseScroll(e));
        this.mainStage.container().addEventListener('dragover', e => e.preventDefault());
        this.mainStage.container().addEventListener('drop', e => this.handleCanvasDrop(e));
        this.mainStage.container().ownerDocument.defaultView?.addEventListener("paste", (e: Event) => {
            // e.preventDefault();
            e.stopImmediatePropagation();
            if ((window as any).listenPaste) {
                const clipEvent = e as ClipboardEvent;
                if (clipEvent.clipboardData) {
                  const clipData = clipEvent.clipboardData;
                  if (clipData.types.length > 0) {
                    const rawData = clipData.getData(clipData.types[0]);
                    this.insertImage(rawData, false, (s) => {
                      if (!s) {
                        this.addText(rawData);
                      }
                    });
                  }
                }
            }
          });
    }

    /**
     * Handle drag event to perform dragdrop
     * @param e Drag Event
     */
    private handleCanvasDrop(e: DragEvent) {
        e.preventDefault();
        if (e.dataTransfer && e.dataTransfer.types.length > 0) {
            if (e.dataTransfer.types[0] === "text/plain") {
                const rawData = e.dataTransfer.getData("text/plain");
                this.insertImage(rawData, false, (s) => {
                    if (!s) {
                        this.addText(rawData);
                    }
                })
                return;
            }
            const filesList = e.dataTransfer.files;
            if (filesList.length > 0) {
                const fileData = filesList[0];
                if (fileData.type.startsWith("image/")) {
                    this.insertImage(fileData);
                } else {
                    // console.log(fileData);
                }
                return;
            }
        }
    }

    /**
     * Handler for zooming and panning
     * @param e Wheel Event
     */
    private handleMouseScroll(e: KonvaEventObject<WheelEvent>) {

        e.evt.preventDefault();
        const oldScale = Number.parseFloat(this.mainStage.scaleX().toFixed(2));

        const pointer = this.mainStage.getPointerPosition() as Vector2d;

        const mousePointTo = {
          x: (pointer.x - this.mainStage.x()) / oldScale,
          y: (pointer.y - this.mainStage.y()) / oldScale,
        };

        let newScale = Number.parseFloat((e.evt.deltaY > 0 ? oldScale + 0.10 : oldScale - 0.10).toFixed(2));
        newScale = Math.round(newScale*100);
        newScale = (newScale - newScale % 10)/100;
        this.scaleStage(newScale, pointer, mousePointTo);
    }

    private scaleStage(newScale: number, pointer: Vector2d, mousePointTo: { x: number; y: number }) {
        newScale = (newScale >= 20) ? 20.00 : newScale;
        newScale = (newScale <= 0.10) ? 0.10 : newScale;


        this.mainStage.scale({x: newScale, y: newScale});

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        this.mainStage.position(newPos);
        this.mainStage.batchDraw();
        this.emit('zoom', newScale);
        return newScale;
    }

    /**
     * Handler for mouse double click
     * @param e Mouse event
     */
    handleMouseDoubleClick(e: KonvaEventObject<MouseEvent>): void {
        if (this.drawingMode || !e.target) return; // if in drawing mode then do nothing
        
        /**
         * TODO Implement inline text editing function
         */

        throw new Error("Method not implemented.");
    }

    /**
     * Handler for mouse up event
     * @param e Mouse event
     */
    handleMouseUp(e: KonvaEventObject<MouseEvent>): void {
        if (! e.target) return;

        if (this.drawingMode) {
            this.transferDrawingToBaseLayer();
            setTimeout(() => {
                this.selectStarted = false; // set select started to false to enable mouse click
            })
        }
    }

    /**
     * Handler for mouse down on stage
     * @param e Mouse event
     */
    private handleMouseDown(e: KonvaEventObject<MouseEvent>) {

        if (! e.target) return;

        
        if (this.drawingMode && !(e.target instanceof Konva.Stage)) {
            if (e.target.draggable()) {
                return;
            }
        }
        
        this.lastPointerPosition = XKonva.getRelativePointerPosition(this.mainStage) ?? this.lastPointerPosition;

        // Check if drawing mode started
        if (this.drawingMode && this.currentTool !== TOOL.PAN) {
            const currentDrawingShape = this.getShapeObject();
            if (currentDrawingShape instanceof EditText) {
                currentDrawingShape.setAttrs({ 
                    x: this.lastPointerPosition.x,
                    y: this.lastPointerPosition.y
                });
                currentDrawingShape.on('dblclick dbltap', e => this.textEventHandler(e));
                this.drawingLayer.add(currentDrawingShape);
                this.drawingLayer.batchDraw();
            } else {
                this.drawingLayer.add(currentDrawingShape);
            }
            return;
        } else {
            this.mainStage.setAttrs({ draggable: true });
        }
    }


    /**
     * Handler for mouse move event on stage
     * @param e Mouse Event
     */
    private handleMouseMove(e: KonvaEventObject<MouseEvent>) {
        if (! e.target) return;

        const newPointerPosition = XKonva.getRelativePointerPosition(this.mainStage) ?? this.lastPointerPosition;

        if (this.drawingMode && this.drawingLayer.getChildren().length > 0) {
            const currentDrawingShape = this.drawingLayer.getChildren()[0];
            switch (this.currentTool) {
                case TOOL.LINE:
                case TOOL.ARROW:
                    currentDrawingShape.setAttrs({ points: (currentDrawingShape as Konva.Line).points().slice(0,2).concat(newPointerPosition.x, newPointerPosition.y) });
                    break;
                    
                case TOOL.PEN:
                    currentDrawingShape.setAttrs({ points: (currentDrawingShape as Konva.Line).points().concat(newPointerPosition.x, newPointerPosition.y) });
                    break;

                
                case TOOL.RECTANGLE:
                    currentDrawingShape.setAttrs({ 
                        x: Math.min(this.lastPointerPosition.x, newPointerPosition.x),
                        y: Math.min(this.lastPointerPosition.y, newPointerPosition.y),
                        width: (this.symmetryKeyPressed)?Math.max(Math.abs(this.lastPointerPosition.x - newPointerPosition.x), Math.abs(this.lastPointerPosition.y - newPointerPosition.y)):Math.abs(this.lastPointerPosition.x - newPointerPosition.x),
                        height: (this.symmetryKeyPressed)?Math.max(Math.abs(this.lastPointerPosition.x - newPointerPosition.x), Math.abs(this.lastPointerPosition.y - newPointerPosition.y)):Math.abs(this.lastPointerPosition.y - newPointerPosition.y)
                    });
                    break;
                
                case TOOL.CIRCLE:
                    currentDrawingShape.setAttrs({
                        x: (currentDrawingShape.x() === 0)?1:Math.min(this.lastPointerPosition.x, Infinity),
                        y: (currentDrawingShape.y() === 0)?1:Math.min(this.lastPointerPosition.y, Infinity),
                        radiusX: (this.symmetryKeyPressed)?Math.max(Math.abs(this.lastPointerPosition.x - newPointerPosition.x), Math.abs(this.lastPointerPosition.y - newPointerPosition.y)):Math.abs(this.lastPointerPosition.x - newPointerPosition.x),
                        radiusY: (this.symmetryKeyPressed)?Math.max(Math.abs(this.lastPointerPosition.x - newPointerPosition.x), Math.abs(this.lastPointerPosition.y - newPointerPosition.y)):Math.abs(this.lastPointerPosition.y - newPointerPosition.y)
                    });
                    break;

                case TOOL.POLYGON:
                    currentDrawingShape.setAttrs({
                        x: Math.min(this.lastPointerPosition.x, Infinity),
                        y: Math.min(this.lastPointerPosition.y, Infinity),
                        radius: Math.max(Math.abs(this.lastPointerPosition.x - newPointerPosition.x), Math.abs(this.lastPointerPosition.y - newPointerPosition.y))
                    });
                    break;

                case TOOL.SELECT:
                    if (e.target.className !== "xSelect") {
                        this.selectStarted = true; // NOTE temp variable for checking if selection is started
                        currentDrawingShape.setAttrs({ 
                            x: Math.min(this.lastPointerPosition.x, newPointerPosition.x),
                            y: Math.min(this.lastPointerPosition.y, newPointerPosition.y),
                            width: Math.abs(this.lastPointerPosition.x - newPointerPosition.x),
                            height: Math.abs(this.lastPointerPosition.y - newPointerPosition.y)
                        });
                    } 
                    break;

                case TOOL.PAN:
                    if (! this.mainStage.draggable()) {
                        this.mainStage.draggable(true);
                    }
                    break;
            }
            this.mainStage.batchDraw();
        }
    }

    /**
     * Handler for mouse click event on stage
     * @param e Mouse event
     */
    private handleMouseClick(e: KonvaEventObject<MouseEvent>) {

        keyboardjs.resume();


        if (!e.target || this.selectStarted) return; // return if target is null | undefined

        /**
         * Check if mouse is clicked on stage OR current target is null
         */
        if (e.target instanceof Konva.Stage) {
            
            // deselect all nodes/shape
            this.xTransformer.deselectNode();
            
        } else if(e.target.getParent() instanceof Konva.Transformer) {
            // Do nothing
        } else {
            // check if drawing layer is not visible
            // if (! this.drawingLayer.isVisible()) {
            // FIXME this need to be fixed
            // }
            this.xTransformer.selectNode(e.target)
        }
    }

    /**
     * Set and initializes stage container with canvas
     * @param mainStage container or id of the container
     * @param config
     */
    public setStage(mainStage?: Vue | Vue[] | Element | Element[] | string, config?: NodeConfig) {
        if (mainStage !== undefined) {
            if (Array.isArray(mainStage)) {
                throw "mainStage parameter is Array; Vue or Element or String required";
            }
    
            if (typeof(mainStage) === "string" ) {
                this.mainStage = Konva.Stage.create({}, mainStage);
            } else {

                this.mainStage = (mainStage as unknown as any).getNode() as Konva.Stage;
            }
            this.mainStage.setAttrs(config ?? {});
        }
    }

    /**
     * start drawing mode and set necessary parameter
     * @param shape Shape to draw
     * @param sides
     */
    public setTool(shape: TOOL, sides?: number) {
        if (this.mainStage.draggable()) {
            this.mainStage.draggable(false);
        }
        if (shape !== TOOL.PAN) {
            this.xTransformer.deselectNode();
        }
        this.currentTool = shape;
        this.polygonSides = (sides && sides > 2)?sides:3;
        this.drawingMode = true; // NOTE this should be set last after setting all configs for drawing
    }

    /**
     * Get Konva Shape relating to specified high level shape
     * @param shape Konva shape object related to high level shapes
     */
    private getShapeObject(shape?: TOOL): Konva.Shape | Konva.Line | Konva.Arrow | Konva.Circle | Konva.RegularPolygon | Konva.Rect {
        switch (shape ?? this.currentTool) {
            case TOOL.LINE:
                return new Konva.Line({ points: [], fill: this.color, stroke: this.color , strokeWidth: this.strokeWidth, strokeScaleEnabled: false , name: "xShape"});

            case TOOL.PEN:
                return new Konva.Line({ points: [], fill: this.color, stroke: this.getComputedColor() , strokeWidth: this.strokeWidth, strokeScaleEnabled: false, tension: 0.5, lineCap: 'round', lineJoin: 'bevel', name: "xShape"});
            
            case TOOL.RECTANGLE:
                return new Konva.Rect({ name: 'xShape', stroke: this.color, strokeWidth: this.strokeWidth, fill: this.getComputedColor(), strokeScaleEnabled: false });

            case TOOL.CIRCLE:
                return new Konva.Ellipse({ name: 'xShape', radiusX: 0, radiusY: 0, fill: this.getComputedColor(), stroke: this.color, strokeWidth: this.strokeWidth });
            
            case TOOL.ARROW:
                return new Konva.Arrow({ name: 'xShape', points: [], stroke: this.color , strokeWidth: this.strokeWidth, fill: this.color });
            
            case TOOL.POLYGON:
                return new Konva.RegularPolygon({ name: 'xShape', sides: this.polygonSides , fill: this.getComputedColor(), radius: 0, stroke: this.color, strokeWidth: this.strokeWidth });

            case TOOL.TEXT:
                return new EditText({ name: 'xShape', text: "Double click to edit", width: 150, lineHeight: 1.2, fill: this.color } as Konva.TextConfig)

            default:
                return new Konva.Rect({ name: 'xSelect' , fill: '#0000FF25'}); //, stroke: 'blue', strokeWidth: 1, strokeScaleEnabled: true, dash: [5, 2] });
        }
    }

    /**
     * transfer drawingLayer to base layer
     */
    private transferDrawingToBaseLayer() {
        this.handleSelectRegion(); // handle select region at every case
        this.drawingLayer.find('.xShape').each(child => {
            const shapeRect = child.getClientRect();
            // check if shape is valid shape or not
            if (shapeRect.width*shapeRect.height > 10 ) {
                const clone = child.clone() as Konva.Shape;
                clone.setAttrs({ draggable: true });
                clone.on('transformend dragend', (e) => this.handleStateChanges(e));
                this.baseLayer.add(clone);
                this.stateManager.addState(OPERATION.ADDED, this.baseLayer.getChildren().toArray().pop() as Konva.Node)
            }
        });
        this.drawingLayer.removeChildren();
        this.drawingLayer.batchDraw();
        this.baseLayer.batchDraw();
    }


    private handleStateChanges(e: KonvaEventObject<MouseEvent>) {
        switch (e.type) {
            case "transformend":
                this.stateManager.addState(OPERATION.MODIFIED, e.target);
                break;
            case "dragend":
                this.stateManager.addState(OPERATION.MODIFIED, e.target);
                break;
        }
    }

    /**
     * Handle select region and to Transformer
     */
    private handleSelectRegion() {
        if (this.drawingLayer.findOne('.xSelect')) {
            const shapes = this.baseLayer.find('.xShape').toArray();
            const selectionArea = this.getSelectionArea();
            if (selectionArea.height > 10 || selectionArea.width > 10) {
                const selectedShapes = shapes.filter((shape) => Konva.Util.haveIntersection(selectionArea, shape.getClientRect()) && shape.visible());
                this.xTransformer.selectNode(...selectedShapes);
            }
        }
    }

    /**
     * Remove shape from canvas  
     *   
     * if `shapes` is null or empty,  
     * then it'll find selected shapes and will be removed from canvas
     * @param removeSelected pass false if you want all to be removed, it won't be used if shapes is passed
     * @param shape shapes to remove
     */
    public removeShape(removeSelected: boolean, ...shape: Node[]) {
        const selectedShapes = this.xTransformer.nodes();
        if (shape && shape.length > 0) {
            shape.forEach((shape) => {
                shape.remove();
            })
        } else {
            if (selectedShapes.length > 0) {
                this.xTransformer.deselectNode();
                selectedShapes.forEach((node) => node.remove());
            } else {
                if (!removeSelected) {
                    this.baseLayer.removeChildren();
                }
            }
        }
        this.baseLayer.draw();
    }

    /**
     * Get selection area by point tool
     */
    private getSelectionArea(): IRect {
        if (this.currentTool === TOOL.SELECT) {
            const selectionRectangle = this.drawingLayer.findOne('.xSelect');
            if (selectionRectangle) {
                return selectionRectangle.getClientRect();
            }
        }
        return {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };
    }

    /**
     * Get relative pointer position from node
     */
    private static getRelativePointerPosition(node: Node): Vector2d {
        const transform = node.getAbsoluteTransform().copy();
        // to detect relative position we need to invert transform
        transform.invert();

        // get pointer (say mouse or touch) position
        const pos = (node.getStage() as Konva.Stage).getPointerPosition() as Vector2d;

        // now we find a relative point
        return transform.point(pos);
    }

    /**
     * Handler for text to deselect current text node and stop listening to all shortcut events
     * @param e Mouse Event
     */
    private textEventHandler(e: KonvaEventObject<MouseEvent>) {
        if (! e.target) return;
        this.xTransformer.deselectNode();
        this.mainStage.batchDraw();
        keyboardjs.pause();
    }

    /**
     * Apply color to selected shape and use this color for subsequent shapes
     * @param color Hex value of color (opacity is not considered)
     */
    public setColor(color: string) {
        if (! color.startsWith('#')) return Error('setColor method only takes HEX value');
        this.color = color.substring(0,7);
        this.modifyTransformerNodes();
    }

    /**
     * Apply stroke to eligible selection
     * @param width number of pixel to apply width
     */
    public setStrokeWidth(width: number ) {
        if (width < 1) return Error('minimum width should be 1');
        this.strokeWidth = width;
        this.modifyTransformerNodes();
    }

   
    /**
     * Set opacity to all shapes (text are excluded)
     * @param opacity 2 byte hex number (do not include `#`)
     */
    public setOpacity(opacity: string) {
        if (opacity.startsWith('#') || opacity.length > 2) return Error('Invalid value for opacity');
        this.opacity = opacity;
        this.modifyTransformerNodes();
    }

    /**
     * Apply fill to all selected shapes (including text)
     * @param fill aplly fill
     */
    public shapeFill(fill: boolean) {
        if (this.xTransformer.nodes().length > 0) {
            const nodesToModify = this.xTransformer.nodes().filter((n) => !["Image", "Text"].includes(n.className));
            nodesToModify.forEach((n) => {
                const shape = n as Konva.Shape;
                const preFill = shape.fill().substring(0,7);
                shape.setAttrs({
                    fill: (fill)?preFill.concat(this.opacity):preFill.concat("00")
                });
            })
            this.mainStage.batchDraw();
        }
    }

    /**
     * Apply style to every selected text
     * @param style style to apply
     * @param remove state weather to remove or add
     */
    public textStyle(style: "bold" | "italic" | "normal", remove?: boolean) {
        if (this.xTransformer.nodes().length > 0) {
            const nodesToModify = this.xTransformer.nodes().filter((n) => n.className === "Text");
            nodesToModify.forEach((n) => {
                const node = n as Konva.Text;
                if (remove) {
                    node.fontStyle(node.fontStyle().replace(style, "").trim())
                } else {
                    if (!node.fontStyle().includes(style)) {
                        node.fontStyle(node.fontStyle().concat(" " +style).trim());
                    }
                }
            })
            this.mainStage.batchDraw();
        }
    }

    /**
     * Justify every text element if selected
     * @param justify type of alignment
     */
    public textJustify(justify: TEXT_JUSTIFY) {
        if (this.xTransformer.nodes().length > 0) {
            const nodesToModify = this.xTransformer.nodes().filter((n) => n.className === "Text");
            nodesToModify.forEach((n) => {
                const node = n as Konva.Text;
                node.align(justify.toLowerCase());
            })
            this.mainStage.batchDraw();
        }
    }

    /**
     * Computed color return color with opacity attached to it
     */
    private getComputedColor() {
        return this.color + this.opacity;
    } 
    
    /**
     * Modify transformer node when element data is change
     */
    private modifyTransformerNodes() {
        if (this.xTransformer.nodes().length > 0) {
            const shapesToModify = this.xTransformer.nodes().filter(n => n.className !== "Image");
            shapesToModify.forEach((shape) => {
                if (shape instanceof EditText) {
                    shape.setAttrs({ fill: this.color } as Konva.TextConfig)
                } else if (shape instanceof Konva.Arrow) {
                    shape.setAttrs({ stroke: this.color, fill: this.color , strokeWidth: this.strokeWidth } as Konva.TextConfig)
                } else {
                    
                    if ((shape as Konva.Shape).fill().slice(7,9) === "00") {
                        shape.setAttrs({ fill: this.color.concat("00"),  stroke: this.color, strokeWidth: this.strokeWidth } as Konva.ShapeConfig);
                    } else {
                        shape.setAttrs({ fill: this.getComputedColor(),  stroke: this.color, strokeWidth: this.strokeWidth } as Konva.ShapeConfig);
                    }
                }
                
            });
            // this.stateManager.addState(STATE.MODIFIED, ...shapesToModify);
            this.mainStage.batchDraw();
        }
    }


    public undo() {
        // this.stateManager.moveState("UNDO", this.baseLayer);
        this.stateManager.undo(this.baseLayer);
        this.xTransformer.deselectNode();
    }
    
    public redo() {
        // this.stateManager.moveState("REDO", this.baseLayer);
        this.stateManager.redo(this.baseLayer);
        this.xTransformer.deselectNode();
    }

    /**
     * Duplicate all selected node
     */
    public duplicate() {
        const nodes = this.xTransformer.nodes();
        const clonedNodes: Node[] = [];
        if (nodes.length > 0) {
            nodes.forEach((n) => {
                const node = n.clone({ x: n.x() + 10, y: n.y() + 10 });
                clonedNodes.push(node);
                this.baseLayer.add(node);
            });
            this.baseLayer.batchDraw();
            this.xTransformer.selectNode(...clonedNodes);
        }
    }

    /**
     * Stage zooming and focusing on pointer location
     * @param zoomLevel Stage predefined zoom level
     */
    public stageZoom(zoomLevel: number) {
        const oldScale = this.mainStage.scaleX();

        const pointer = this.mainStage.getPointerPosition() ?? this.lastPointerPosition; // ?? { x: this.mainStage.width()/2, y: this.mainStage.height()/2 };

        const mousePointTo = {
          x: (pointer.x - this.mainStage.x()) / oldScale,
          y: (pointer.y - this.mainStage.y()) / oldScale,
        };

        const newScale = 
            (zoomLevel >= -1 && zoomLevel <= 1)
                ?(oldScale + (Number.parseFloat(zoomLevel.toFixed(2))/10))
                :zoomLevel/100;
        this.scaleStage(newScale,pointer, mousePointTo);
    }

    /**
     * Insert image to stage
     * @param image image resource
     *
     * @param select
     * @param callback
     * @example
     *
     * insertImage('https://www.domian.com/path/to/image.extension');
     */
    public insertImage(image: string | File | HTMLImageElement, select = false, callback?: (success: boolean) => void) {
        const imageElement = new Image();
        imageElement.onload = (e) => {
            const _imageElement = e.target as HTMLImageElement;
            const imageNode = new Konva.Image({
                image: _imageElement,
                width: _imageElement.width,
                height: _imageElement.height,
                x: this.mainStage.width()/2,
                y: this.mainStage.height()/2,
                scale: { x: 0.2, y: 0.2 }, // NOTE this is just for testing purpose 
                name: 'xShape',
                draggable: true
            });
            this.baseLayer.add(imageNode);
            imageNode.on('tranformend dragend', e => this.handleStateChanges(e));
            this.stateManager.addState(OPERATION.ADDED, imageNode);
            if (select) {
                this.xTransformer.selectNode(imageNode);
            }
            this.mainStage.batchDraw();
            if (callback) {
                callback(true);
            }
        };
        imageElement.onerror = () => {
            if (callback) {
                callback(false);
            }
        }
        if (image instanceof HTMLImageElement) {
            imageElement.src = image.src;
        } else if (image instanceof File) {
            imageElement.src = URL.createObjectURL(image);
        } else {
            const svgMatch = image.match(/(<svg)([^<]*|[^>]*)(.*)(<\/svg>)/g);
            if (svgMatch) {
                const base64 = btoa(svgMatch[0]);
                imageElement.src = "data:image/svg+xml;base64," + base64;
            } else {
                imageElement.src = image;
            }
        }
        
        
    }

    /**
     * Lock all selected shapes
     * @param lock lock status
     */
    public lockShape(lock: boolean) {
        const selectedNodes = this.xTransformer.nodes();
        if (selectedNodes.length > 0) {
            selectedNodes.forEach((n) => {
                n.setAttrs({
                    draggable: !lock
                });
            });
            this.baseLayer.batchDraw();
            this.xTransformer.selectNode(...selectedNodes);
        }
    }

    /**
     * Adds Edit text node to the center of the screen
     * @param string content of the text
     */
    public addText(string: string) {
        const textNode = this.getShapeObject(TOOL.TEXT) as Konva.Text;
        textNode.on('dblclick dbltap', e => this.textEventHandler(e));
        textNode.on('treansformend dragend', e => this.handleStateChanges(e));
        textNode.x(this.mainStage.width()/2);
        textNode.y(this.mainStage.height()/2);
        textNode.draggable(true);
        textNode.text(string);
        this.stateManager.addState(OPERATION.ADDED, textNode);
        this.baseLayer.add(textNode);
        this.baseLayer.draw();
    }

}
